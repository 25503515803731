/**
 * 动态路由：本页面的路由将会根据服务器返回权限，进行动态匹配
 */
// 订单管理
import order from './order';

// 充值管理
import recharge from './recharge';

// 会员管理
import member from './member';

// 车辆管理
import vehicle from './vehicle';

// 商品管理
import goods from './goods';

// 财务查询
import finance from './finance';

// 系统配置
import system from './system';

// 运营工具
import tool from './tool';

// 礼品卡
import gift from './gift';

// 礼品卡
import points from './points';

// 优惠券管理
import coupon from './coupon';

// 同行立减规则管理
import promotion from './promotion';

// 次卡管理
import timesCard from './timesCard';

// 会费管理
import fee from './fee';

// 等级管理
import level from './level';

// 年卡管理
import vip from './vip';

// 车辆信息
import car from './carInfo';

// 车辆信息
import map from './mapTrack';

export default [
  ...order,
  ...recharge,
  ...member,
  ...vehicle,
  ...goods,
  ...finance,
  ...system,
  ...tool,
  ...gift,
  ...points,
  ...coupon,
  ...promotion,
  ...timesCard,
  ...fee,
  ...level,
  ...vip,
  ...car,
  ...map,
];
