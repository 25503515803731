export default [
  {
    path: '/mapTrack',
    name: 'mapTrack',
    component: { render(h) { return h('router-view'); } },
    meta: {
      title: '地图管理',
      icon: 'icon-member'
    },
    children: [
      {
        path: 'mapTrackType',
        name: 'mapTrackType',
        component: (resolve) => require(['@/views/mapTrack/MapTrackType.vue'], resolve),
        meta: {
          title: '地图列表',
          icon: 'icon-setting',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'mapTrack',
        }
      },
      {
        path: 'mapTrackTypeAdd',
        name: 'mapTrackTypeAdd',
        component: (resolve) => require(['@/views/mapTrack/MapTrackTypeForm.vue'], resolve),
        meta: {
          title: '添加地图',
          icon: 'icon-setting',
          hideInMenu: true,
          hideInBread: true,
          activeMenu: 'mapTrack',
        }
      },
    ]
  }
];
